<template>
  <div class=" ">
    <titleBar
      title="Edit"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />
    <div class="section">
      <div class="container">
        <h1 class="title is-2">Edit post</h1>

        <!-- buefy switch for generateByAi prop -->

        {{ originalFeaturedImageIndex }} : new {{ featuredImageIndex }}
        <div v-if="post">
          <!-- Manual text   text

          <div class="images">
            <img :src="i.url" alt=" " style="" v-for="i in post.images" :key="i.url" />
          </div>-->

          <div class="images">
            <img
              :src="i.url"
              alt=" "
              :class="{ 'featured-image': index === featuredImageIndex }"
              v-for="(i, index) in post.images"
              :key="i.url"
              @click="setFeatured(index)"
            />
          </div>

          <b-field :label="post.images?.length ? 'Caption' : 'Text'" :type="validationErrors.text ? 'is-danger' : ''">
            <b-input v-model="post.text" type="textarea" placeholder="Write something..." expanded></b-input>
            <p class="help is-danger"></p>
          </b-field>

          <!-- buefy switches for deleted, draft -->
          <b-field label="">
            <b-switch v-model="post.deleted" type="is-danger" rounded>Deleted</b-switch>
          </b-field>
          <b-field label="">
            <b-switch v-model="post.draft" type="is-warning" rounded>Draft</b-switch>
          </b-field>

          <b-button class="is-link" @click="clickSave">Save changes</b-button>
        </div>
      </div>

      <!-- 
      {{ generateWithAi }} ==generateWithAi

      <br />
      {{ isText }} == isText -->
      <!--  

   <hr />
        <b-field label="Upload image">
          <b-upload v-model="image" expanded :multiple="true">
            <span class="button is-primary">
              <b-icon icon="upload"></b-icon>
              <span>Click to upload</span>
            </span>
          </b-upload>
        </b-field>
        <div class="image-preview" v-if="imageUrl">
          <img :src="imageUrl" alt="Post image preview" />
        </div>-->
    </div>
    <json-debug :data="post" />
  </div>
</template>

<script>
import JsonDebug from "../components/e/jsonDebug.vue";
export default {
  props: {},
  components: {
    JsonDebug,
  },
  data() {
    return {
      text: null,
      post: null,
      //image: this.post.image,
      validationErrors: {},

      dropFiles: [], //important upuadl
      originalFeaturedImageIndex: null,

      //

      aiPhotoPrompt: "",
    };
  },
  computed: {
    botId() {
      return this.$route.params.bot;
    },
    /* */
    postId() {
      return this.$route.params.postId;
    },
    featuredImageIndex() {
      return this.post.featuredImageIndex || 0;
    },
    /*
    isTxt() {
      var n = this.$route.name;
      return n;
      if (this.$route.name && this.$route.name == "newTextBotPost") return true;
      else return false;
      return (this.$route.name = "newTextBotPost");
    },*/
  },
  mounted() {
    this.loadPost();
  },
  methods: {
    loadPost() {
      var postId = this.postId;
      window.API.getBotPostOwner(this.botId, postId).then((res) => {
        console.log(">>getBotPost res >> ", res);
        this.post = res;
        //coppy the value, not refetence
        this.originalFeaturedImageIndex = this.post.featuredImageIndex || 0;

        // this.text = res.text;
      });
    },
    setFeatured(index) {
      console.log(index);
      //this.post.featuredImageIndex = index;
      /// use $set
      this.$set(this.post, "featuredImageIndex", index);
    },
    clickSave() {
      var text = this.post.text;
      // var image = this.image;
      var postId = this.postId;
      var deleted = this.post.deleted;
      var draft = this.post.draft;
      var featuredImageIndex = this.post.featuredImageIndex || 0;

      var updt = { text, draft, deleted, featuredImageIndex };
      //  var flaggedForReclassify = this.post.flaggedForReclassify;
      //add the flag if it changed
      if (this.originalFeaturedImageIndex != this.post.featuredImageIndex) {
        console.log(this.originalFeaturedImageIndex, this.post.featuredImageIndex);
        updt.flaggedForReclassify = true;
      }
      window.API.editBotPost(this.botId, postId, updt).then((res) => {
        console.log(">>editBotPost res >> ", res);
        this.$router.go(-1);
        this.$buefy.snackbar.open({
          message: "Post saved",
          type: "is-success",
          position: "is-bottom",
          actionText: "Close",
          //indefinite: true,
        });
      });
    },
    /*
    clickGeneratePhoto() {
      var prompt = this.aiPhotoPrompt;
      var text = this.text;
      // generateBotImagePost;

      window.API.editBotPost(this.botId, { text }).then((res) => {
        console.log(">>editBotPost res >> ", res);
        //snackbar buefy confirm save
        this.$buefy.snackbar.open({
          message: "Post saved",
          type: "is-success",
          position: "is-bottom",
          actionText: "Close",
          indefinite: true,
        });

        this.redirectSuccess(res.postId);
      });
    },*/

    redirectSuccess(postId) {
      var url = window.location.href;
      //encoded url

      //remove host, keep path
      url = url.replace(window.location.origin, "");
      url = encodeURIComponent(url);

      this.$router.push({ name: "newBotPostFinish", params: { bot: this.botId, postId }, query: { re: url } });
    },
    resetForm() {
      this.text = "";
      this.image = "";
    },
  },
};
</script>
<style scoped>
.images img {
  height: 150px;
  margin-right: 3px;
  display: inline-block;
  border: 3px solid white;
  padding: 5px;
  border-radius: 5px;
}

img.featured-image {
  border-color: blue;
}
</style>
